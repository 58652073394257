<template>
    <v-card>
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2"
        >
          mdi-email-outline
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                cols="5"
              >
                <v-text-field
                  v-model="form.realName"
                  :label="$vuetify.lang.t('$vuetify.user.realName')"
                  clearable
                />
              </v-col>

              <v-col
                cols="5"
              >
                <v-select
                  v-model="form.depId"
                  :label="$vuetify.lang.t('$vuetify.user.dep')"
                  :items="departments"
                  item-text="showName"
                  item-value="id"
                  clearable
                />
              </v-col>

              <v-col
                cols="2"
              >
                <v-btn
                  class="mx-2 mt-2"
                  color="primary"
                  fab
                  dark
                  small
                  @click="handleSearch"
                >
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  export default {
    data() {
      return {
        form: {
          realName: null,
          depId: null
        },
        depItems: []
      }
    },
    computed: {
      ...mapGetters({
        departments: 'department/departments'
      }),
    },
    created () {
      this.departmentQueryAll()
    },
    methods: {
      ...mapActions({
        departmentQueryAll: 'department/queryAll'
      }),
      handleSearch() {
        const data = {}
        for (let k in this.form) {
          if (this.form[k]) {
            data[k] = this.form[k]
          }
        }
        this.$emit('submit', data)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
